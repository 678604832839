import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLess from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/Info';
import LinkIcon from '@mui/icons-material/Link';
import ReplyIcon from '@mui/icons-material/Reply';
import SendIcon from '@mui/icons-material/Send';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import DatePicker from '@mui/lab/DatePicker';
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';

import ApplicationTheme from '../../../../../../../application/theme/ApplicationTheme';
import { AdditionalColumnType } from '../../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { IChatModel } from '../../../../../../../service/chat/entity/IChatModel';
import { IMessageModel } from '../../../../../../../service/chat/message/IMessageModel';
import { serverToLocalDate, timeSince } from '../../../../../../../service/date/since';
import { IRequirementModel } from '../../../../../../../service/requirement/entity/IRequirementModel';
import { IUserEntityModel } from '../../../../../../../service/user/IUserEntityModel';
import { Colors } from '../../../../../../design/color/Colors';
import { DownloadFileColumn } from '../../../../../../design/fileUpload/DownloadFileColumn';
import { FileUploadDomain } from '../../../../../../design/fileUpload/store/FileUploadDomain';
import { IFileUploadDomain } from '../../../../../../design/fileUpload/store/IFileUploadDomain';
import { UploadFileColumn } from '../../../../../../design/fileUpload/UploadFileColumn';
import { FormAutocomplete, FormAutocompleteOptionIconPosition } from '../../../../../../design/form/FormAutocomplete';
import { ConfirmModal } from '../../../../../../design/modal/confirm/ConfirmModal';
import { ApplicationDetailDomain } from '../../store/ApplicationDetailDomain';
import { ApplicationDetailsMode } from '../../store/ApplicationDetailsMode';
import { RequirementViewModel, RequirementViewModelState } from '../../store/RequirementViewModel';
import { RichTextArea } from '../../../../../../design/RichTextEditor/RichTextEditor';
import { useI18n } from '../../../../../../../application/language/useI18n';
import { ITableData } from './ActiveRequirementsTable';

export const StatusAutocomplete = observer(
  ({ domain, statusId, rowDataId, appReqId, isDisabled, noPadding = false, isTestStatus = false }: any) => {
    const classes = useRequirementTableStyles();
    const activeStatusValueId = domain.ui.getStatusData(rowDataId, statusId)?.statusValueId;
    const [statusesValues, setStatusesValues] = React.useState<any[]>([
      {
        value: null,
        name: '',
      },
      ...domain.ui.getStatusesValues(statusId, activeStatusValueId),
    ]);

    return (
      <div className={classes.statusColumn}>
        <FormAutocomplete
          dataCy={'application-req-status-filter'}
          disabled={isDisabled}
          label={undefined}
          onChangeSelect={domain.getSetStatusValueHandler(rowDataId, statusId, isTestStatus, appReqId)}
          values={statusesValues}
          selected={activeStatusValueId}
          controlPadding={noPadding ? '0px' : undefined}
          key={statusId + rowDataId}
          onOpen={async () => {
            const statusesSyncAvailableMap = await domain.loadStatusValuesSyncAvailableMap(appReqId, statusId);
            const statusValuesWithTaskManagerIcons = statusesValues.map((statusesValue) => {
              statusesValue.icons = [];
              const isMappedItem = statusesSyncAvailableMap.find(
                (mapItem: any) => mapItem.statusValueId === statusesValue.value,
              );
              const isNotAvailable = isMappedItem ? !isMappedItem.isAvailable : false;
              if (isNotAvailable && statusesValue.value !== null) {
                statusesValue.icons = [
                  {
                    position: FormAutocompleteOptionIconPosition.right,
                    fragment: <SyncDisabledIcon style={{ color: Colors.negativePassive }} fontSize='medium' />,
                  },
                ];
              }
              return statusesValue;
            });
            setStatusesValues([...statusValuesWithTaskManagerIcons]);
          }}
        />
      </div>
    );
  },
);

export const AdditionalColumnInfo = observer(
  ({
    additionalColumnContent = [],
    extraStatuses = [],
    rowData,
    domain,
  }: {
    additionalColumnContent: any[];
    extraStatuses: any[];
    domain: ApplicationDetailDomain;
    rowData: ITableData;
  }) => {
    const classes = useRequirementTableStyles();
    const isNewestRequirement = rowData.requirement.state === RequirementViewModelState.newest;
    const [uploadDomain] = useState<IFileUploadDomain>(new FileUploadDomain(domain.layoutDomain));

    return (
      <Fragment>
        {extraStatuses &&
          extraStatuses.length > 0 &&
          extraStatuses.map((extraStatusData: any, index: number) => {
            return (
              <Paper
                key={`additional_status_${index}`}
                style={{ paddingTop: '25px', paddingLeft: '12px' }}
                elevation={2}
              >
                <Typography style={{ paddingLeft: '17px' }} variant='h5'>
                  {extraStatusData.title}
                </Typography>
                <div style={{ paddingLeft: '17px' }}>{extraStatusData.render(rowData)}</div>
                <div style={{ height: '34px' }} />
              </Paper>
            );
          })}
        {additionalColumnContent.map((contentData: any, index: number) => {
          const isDisabled = isNewestRequirement || !contentData.isCanEdit;
          const editData = contentData.editData;
          const changeHandler = domain.getSetAdditionalColumnDataValueHandler(
            editData.requirementId,
            editData.additionalColumnId,
            editData.additionalColumnContentId,
            rowData.appReqId
          );
          return (
            contentData.editData.additionalColumnId && (
              <Paper
                key={`additional_data_${index}`}
                className={classes.additionalColumnContentContainer}
                elevation={2}
              >
                {contentData.type === 'date' && (
                  <Fragment>
                    <Typography variant='h5'>{contentData.title}</Typography>
                    <ApplicationTheme isFlip={true}>
                      <DatePicker
                        // variant="dialog"
                        showTodayButton={true}
                        // cancelLabel={'Отмена'}
                        // clearLabel={'Очистить'}
                        // okLabel={'Ok'}
                        // todayLabel={'Сегодня'}
                        label={contentData.text}
                        // format="dd/MM/yyyy"
                        value={editData.value || null}
                        onChange={(date: any) => {
                          changeHandler(date?.getTime() || null);
                        }}
                        InputProps={{
                          color: 'secondary',
                        }}
                        disabled={isDisabled}
                        renderInput={(props) => <TextField {...props} />}
                      // KeyboardButtonProps={{
                      //   'aria-label': 'изменить дату',
                      //   // color: "secondary"
                      // }}
                      // style={{ width: '19%' }}
                      />
                    </ApplicationTheme>
                  </Fragment>
                )}
                {contentData.type === AdditionalColumnType.EDITABLE_TEXT && (
                  <Fragment>
                    <Typography variant='h5'>{contentData.title}</Typography>
                    <TextField
                      label={contentData.text}
                      multiline
                      rows={10}
                      defaultValue={editData.value}
                      style={{ width: '50%' }}
                      disabled={isDisabled}
                      onChange={(event) => {
                        changeHandler(event.target.value || '');
                      }}
                    />
                  </Fragment>
                )}
                {contentData.type === AdditionalColumnType.ENUM && (
                  <Fragment>
                    <Typography variant='h5'>{contentData.title}</Typography>
                    <RichTextArea
                      data={contentData.text}
                      dataWithStyles={contentData.textWithStyles}
                      isNeedShowTable={true}
                    />
                  </Fragment>
                )}
                {contentData.type === AdditionalColumnType.LINK && (
                  <Fragment>
                    <Typography variant='h5'>{contentData.title}</Typography>
                    <Link target='_blank' variant='body1' href={contentData.text} style={{ width: '50%' }}>
                      {contentData.text}
                    </Link>
                  </Fragment>
                )}
                {contentData.type === AdditionalColumnType.UPLOAD_FILE && (
                  <Fragment>
                    <Typography variant='h5'>{contentData.title}</Typography>
                    {!editData.value ? (
                      <UploadFileColumn
                        uploadDomain={uploadDomain}
                        onChange={(event) => {
                          changeHandler(event.url, event.fileName);
                        }}
                      />
                    ) : (
                      <DownloadFileColumn
                        uploadDomain={uploadDomain}
                        originalFileName={editData.originalFileName}
                        defaultValue={editData.value}
                        isCanCancel={true}
                        cancelFile={() => {
                          changeHandler('', '');
                        }}
                      />
                    )}
                  </Fragment>
                )}
                {contentData.type === AdditionalColumnType.DOWNLOAD_FILE && (
                  <Fragment>
                    <Typography variant='h5'>{contentData.title}</Typography>
                    <DownloadFileColumn
                      uploadDomain={uploadDomain}
                      originalFileName={contentData.text}
                      defaultValue={contentData.uploadedFileUrl}
                      isCanCancel={false}
                    />
                  </Fragment>
                )}
              </Paper>
            )
          );
        })}
      </Fragment>
    );
  },
);

export const VendorInfo = observer(({ vendorInfo = [] }: any) => {
  const classes = useRequirementTableStyles();
  return (
    <Fragment>
      {vendorInfo.map((vendorData: any, index: number) => {
        return (
          <Paper key={index} className={classes.additionalColumnContentContainer} elevation={2}>
            <Typography variant='h5'>{vendorData.title}</Typography>
            <div style={{ paddingTop: '5px' }}>
              <RichTextArea dataWithStyles={vendorData.textWithStyles} data={vendorData.text} />
            </div>
          </Paper>
        );
      })}
    </Fragment>
  );
});

export const TaskManagerRequirementChat = observer(
  ({ domain, rowData }: { rowData: ITableData; domain: ApplicationDetailDomain }) => {
    const classes = useRequirementTableStyles();
    const [userChat, setUserChat] = useState<IChatModel | null>(null);
    const [messages, setMessages] = useState<IMessageModel[]>([]);
    const [replyMessage, setReplyMessage] = useState<IMessageModel | null>(null);
    const [editMessage, setEditMessage] = useState<IMessageModel | null>(null);
    const [editMessageText, setEditMessageText] = useState<string>('');
    const [newMessageText, setNewMessageText] = useState<string>('');

    const isEditMode = domain.ui.mode.value === ApplicationDetailsMode.edit;
    const isNewestRequirement = rowData.requirement.state === RequirementViewModelState.newest;
    const isNeedRender = !(isEditMode || isNewestRequirement);
    const i18n = useI18n()

    const activeUserFirstName = domain.layoutDomain.ui.activeUser.entity.displayName.split(' ')[0] || '';
    const activeUserLastName = domain.layoutDomain.ui.activeUser.entity.displayName.split(' ')[1] || '';
    useEffect(() => {
      const loadData = async () => {
        const chat = await domain.loadChatForRequirement(rowData.appReqId);
        setUserChat(chat);
        const messages = await domain.loadChatMessagesForRequirement(rowData.appReqId);
        setMessages(messages);
      };
      loadData();
    }, [rowData.appReqId, domain]);

    useEffect(() => {
      const messagesDetectionData =
        userChat?.messagesState?.unreadedMessagesIds.map((messageId) => ({
          handler: async () => {
            if (userChat?.messagesState?.unreadedMessagesIds) {
              userChat.messagesState.unreadedMessagesIds = userChat.messagesState?.unreadedMessagesIds.filter(
                (id) => id !== messageId,
              );
              await domain.readMessages(userChat.id || '', [messageId || ''], rowData.appReqId);
              setUserChat({ ...userChat });
            }
          },
          idName: `comment-${userChat?.id}-${messageId}`,
          isOnce: true,
        })) || [];
      domain.layoutDomain.ui.addDetections(messagesDetectionData);
      return () => {
        domain.layoutDomain.ui.removeDetections(messagesDetectionData);
      };
    }, [messages, rowData.appReqId, domain, userChat]);

    enum MessageMode {
      view,
      edit,
    }

    const deleteConfirmation = (rowData: ITableData, message: IMessageModel) => {
      return () => (
        <ConfirmModal
          title='Удалить комментарий?'
          text={`Вы уверены, что хотите удалить комментарий ?`}
          onCancel={async () => {
            domain.layoutDomain.modalWindow.closeModalWindow();
          }}
          onOk={async () => {
            domain.layoutDomain.modalWindow.closeModalWindow();
            domain.deleteMessage(rowData.requirement.id || '', message.id || '', rowData.appReqId);
            setMessages(messages.filter((filterMessage) => filterMessage.id !== message.id));
          }}
        />
      );
    };

    const onTryToDelete = (requirement: ITableData, message: IMessageModel) => {
      domain.layoutDomain.modalWindow.showModalWindow(deleteConfirmation(requirement, message));
    };

    const [contextMenu, setContextMenu] = React.useState<any>(null);

    // const getHandleContextMenu = (message: IMessageModel) => {
    //   return (event: any) => {
    //     event.preventDefault();
    //     setContextMenu(
    //       contextMenu === null
    //         ? {
    //             //@ts-ignore
    //             mouseX: event.clientX - 2,
    //             //@ts-ignore
    //             mouseY: event.clientY - 4,
    //           }
    //         : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
    //           // Other native context menus might behave different.
    //           // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
    //           null,
    //     );
    //   };
    // };

    const handleClose = () => {
      setContextMenu(null);
    };

    return (
      <Fragment>
        {isNeedRender && (
          <Paper className={classes.additionalColumnContentContainer} elevation={3}>
            <Menu
              id={`context-menu-${userChat?.id}`}
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference='anchorPosition'
              anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
            >
              <MenuItem onClick={handleClose}>Ответить</MenuItem>
              <MenuItem onClick={handleClose}>Открыть ссылку</MenuItem>
              <MenuItem onClick={handleClose}>Редактировать</MenuItem>
              <MenuItem onClick={handleClose}>Удалить</MenuItem>
            </Menu>
            {userChat && <Typography variant='h5'>Комментарий</Typography>}

            {userChat && <Divider style={{ width: '152px', margin: '30px 0' }} />}
            {userChat &&
              messages.map((message: IMessageModel) => {
                const mode = editMessage?.id === message.id ? MessageMode.edit : MessageMode.view;
                const firstName = message.chatUserFullName?.split(' ')[0] || '';
                const lastName = message.chatUserFullName?.split(' ')[1] || '';
                const parentMessage = messages.find((parentMessage) => parentMessage.id === message.parentId);
                const parentMessageText =
                  ((parentMessage?.text?.length || 0) > 30
                    ? `${parentMessage?.text?.substr(0, 30)}...`
                    : parentMessage?.text) || 'Удаленно';
                const parentMessageUserName = parentMessage?.chatUserFullName || ' ';
                const isUnreaded = userChat.messagesState?.unreadedMessagesIds.includes(message.id || ' ');
                const transitionTime = '500ms';
                return (
                  <Paper
                    className={classes.additionalColumnContentContainer}
                    elevation={2}
                    style={{
                      marginBottom: 5,
                      ...(isUnreaded && {
                        backgroundColor: blueGrey[800],
                        '-webkit-transition': `background-color ${transitionTime} linear`,
                        '-ms-transition': `background-color ${transitionTime} linear`,
                        transition: `background-color ${transitionTime} linear`,
                      }),
                      ...(!isUnreaded && {
                        backgroundColor: Colors.mainPassive,
                        '-webkit-transition': `background-color ${transitionTime} linear`,
                        '-ms-transition': `background-color ${transitionTime} linear`,
                        transition: `background-color ${transitionTime} linear`,
                      }),
                    }}
                  >
                    <Grid
                      container
                      wrap='nowrap'
                      key={`comment-${userChat.id}-${message.id}`}
                      id={`comment-${userChat.id}-${message.id}`}
                      spacing={2}
                    >
                      <Grid item>
                        <Avatar alt={message.chatUserFullName} src={message.chatUserAvatarUrl || ''}>
                          {firstName[0]?.toLocaleUpperCase() || ''}
                          {lastName[0]?.toLocaleUpperCase() || ''}
                        </Avatar>
                      </Grid>
                      <Grid justifyContent='flex-start' item xs zeroMinWidth>
                        <h4 style={{ margin: 0, paddingBottom: 10, textAlign: 'left' }}>{message.chatUserFullName}</h4>
                        {message.parentId && (
                          <Paper
                            style={{ paddingLeft: 5, borderLeft: `medium solid ${Colors.mainActive}` }}
                            elevation={0}
                          >
                            <Grid container wrap='nowrap' key={`comment-${userChat.id}-${message.id}`} spacing={1}>
                              <Grid justifyContent='flex-start' item xs zeroMinWidth>
                                {parentMessage && (
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      (window.location.href = `#comment-${userChat.id}-${parentMessage.id}`)
                                    }
                                  >
                                    <p style={{ margin: 0, textAlign: 'left' }}>{parentMessageUserName}</p>
                                    <p style={{ textAlign: 'left', margin: 0 }}>{parentMessageText}</p>
                                  </div>
                                )}
                                {!parentMessage && <p style={{ margin: 0, textAlign: 'left' }}>Сообщение удаленно</p>}
                              </Grid>
                            </Grid>
                          </Paper>
                        )}

                        {mode === MessageMode.view && (
                          <p style={{ textAlign: 'left', whiteSpace: 'pre-line' }}>{message.text}</p>
                        )}
                        {mode === MessageMode.edit && (
                          <p style={{ textAlign: 'left', color: 'gray' }}>
                            <TextField
                              id={`comment-edit-message-input=${message.id}`}
                              variant='outlined'
                              size='small'
                              label='Редактировать комментарий'
                              multiline
                              maxRows={25}
                              defaultValue={message.text}
                              color='secondary'
                              fullWidth
                              onChange={(event) => {
                                setEditMessageText(event.target.value);
                              }}
                            />
                          </p>
                        )}
                        {mode === MessageMode.view && (
                          <p style={{ textAlign: 'left', color: 'gray' }}>
                            {timeSince(serverToLocalDate(new Date(message.createDate || 0)), i18n)}
                          </p>
                        )}
                        {mode === MessageMode.view && (
                          <p style={{ textAlign: 'left', color: 'gray', marginLeft: '-10px' }}>
                            <IconButton
                              onClick={() => {
                                setReplyMessage(message);
                                window.location.href = `#add-message-${userChat.id}`;
                              }}
                            >
                              <ReplyIcon style={{ fontSize: 20 }} />
                            </IconButton>
                            {message.taskManagerLink && (
                              <IconButton
                                onClick={() => {
                                  window.open(message.taskManagerLink || '', '_blank');
                                }}
                              >
                                <LinkIcon style={{ fontSize: 20 }} />
                              </IconButton>
                            )}
                            {message.senderId === userChat.userId && (
                              <IconButton
                                onClick={async () => {
                                  setEditMessage(message);
                                }}
                              >
                                <EditIcon style={{ fontSize: 20 }} />
                              </IconButton>
                            )}
                            {message.senderId === userChat.userId && (
                              <IconButton
                                onClick={() => {
                                  onTryToDelete(rowData, message);
                                }}
                              >
                                <DeleteIcon style={{ fontSize: 20 }} />
                              </IconButton>
                            )}
                          </p>
                        )}
                        {mode === MessageMode.edit && (
                          <p style={{ textAlign: 'left', color: 'gray', marginLeft: '-10px' }}>
                            <IconButton
                              onClick={async () => {
                                message.text = editMessageText;
                                await domain.updateMessage(rowData.appReqId, message);
                                setEditMessage(null);
                              }}
                            >
                              <DoneIcon style={{ fontSize: 20 }} />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setEditMessage(null);
                              }}
                            >
                              <CancelIcon style={{ fontSize: 20 }} />
                            </IconButton>
                          </p>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}

            {userChat && (
              <Paper
                id={`add-message-${userChat.id}`}
                className={classes.additionalColumnContentContainer}
                elevation={2}
              >
                <Grid container wrap='nowrap' style={{ width: '100%' }} key={`comment-add-message`} spacing={2}>
                  <Grid item>
                    <Avatar alt={domain.layoutDomain.ui.activeUser.entity.displayName}>
                      {activeUserFirstName[0]?.toLocaleUpperCase() || ''}
                      {activeUserLastName[0]?.toLocaleUpperCase() || ''}
                    </Avatar>
                  </Grid>
                  <Grid xs={8} item>
                    {replyMessage && (
                      <Paper
                        style={{ paddingLeft: 5, marginBottom: 10, borderLeft: `medium solid ${Colors.mainActive}` }}
                        elevation={0}
                      >
                        <Grid
                          container
                          wrap='nowrap'
                          key={`comment-reply-${userChat.id}-${replyMessage.id}`}
                          spacing={1}
                        >
                          <Grid justifyContent='flex-start' item xs zeroMinWidth>
                            {replyMessage && (
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => (window.location.href = `#comment-${userChat.id}-${replyMessage.id}`)}
                              >
                                <p style={{ margin: 0, textAlign: 'left' }}>{replyMessage.chatUserFullName}</p>
                                <p style={{ textAlign: 'left', margin: 0 }}>{replyMessage.text}</p>
                              </div>
                            )}
                            {!replyMessage && <p style={{ margin: 0, textAlign: 'left' }}>Сообщения не существует</p>}
                          </Grid>
                          <Grid justifyContent='flex-start' item>
                            <IconButton
                              onClick={() => {
                                setReplyMessage(null);
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Paper>
                    )}
                    <TextField
                      id='comment-add-message-input'
                      variant='outlined'
                      size='small'
                      label='Добавить комментарий'
                      multiline
                      maxRows={25}
                      defaultValue={''}
                      value={newMessageText}
                      color='secondary'
                      fullWidth
                      onChange={(event) => setNewMessageText(event.target.value)}
                      inputProps={{
                        'data-cy': 'add-req-status-comment',
                      }}
                    />
                  </Grid>

                  <Grid item style={{ alignSelf: 'flex-end', marginLeft: '-12px', marginTop: '-12px' }}>
                    <IconButton
                      onClick={async () => {
                        const message = await domain.addMessage(newMessageText, rowData.appReqId, userChat, replyMessage);
                        setMessages([...messages, message]);
                        setNewMessageText('');
                        setReplyMessage(null);
                        setTimeout(() => {
                          window.location.href = `#comment-${userChat.id}-${message.id}`;
                        }, 200);
                      }}
                    >
                      <SendIcon style={{ fontSize: 20 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Paper>
        )}
      </Fragment>
    );
  },
);

export const HiddenRequirementInfoBlock = observer(
  ({
    domain,
    requirementId,
    rowData,
  }: {
    requirementId: string;
    rowData: ITableData;
    domain: ApplicationDetailDomain;
  }) => {
    const additionalColumnContent: any[] = domain.ui.getAdditionalColumnsContent(requirementId);
    const extraStatuses = domain.ui.extraStatusesColumns.value;
    const vendorInfo: any[] = domain.ui.getVendorColumnsContent(requirementId);
    const authorInfo: IUserEntityModel | null = domain.ui.getAuthorModel(rowData.requirement.authorId || null);
    const entityId = domain.ui.applicationData.entity.id;

    const classes = useRequirementTableStyles();

    const isNeedRender =
      rowData.requirement.isHaveComments ||
      extraStatuses.length > 0 ||
      additionalColumnContent.length > 0 ||
      vendorInfo.length > 0 ||
      authorInfo;

    return (
      <Fragment>
        {isNeedRender && (
          <Fragment>
            <AdditionalColumnInfo
              additionalColumnContent={additionalColumnContent}
              extraStatuses={extraStatuses}
              rowData={rowData}
              domain={domain}
            />
            {domain.ui.isCanSeeRegulators.value && <VendorInfo vendorInfo={vendorInfo} />}
            {authorInfo && (
              <Paper key={`author-$requirementId`} className={classes.additionalColumnContentContainer} elevation={2}>
                <Typography variant='h5'>Автор</Typography>
                <Typography style={{ whiteSpace: 'pre-line' }} variant='body1'>
                  {authorInfo.displayName}
                </Typography>
              </Paper>
            )}
            {entityId &&
              (domain.ui?.applicationData?.entity?.savedRequirementsIds?.includes(rowData?.appReqId) ||
                domain.ui?.applicationData?.entity?.deletedRequirementsIds?.includes(rowData?.appReqId))
              && <TaskManagerRequirementChat domain={domain} rowData={rowData} />}
          </Fragment>
        )}
      </Fragment>
    );
  },
);

export const EditCustomRequirementIcon = ({ onClick, isOpen }: { onClick: any; isOpen: boolean }) => {
  const classes = useRequirementTableStyles();
  return (
    <div className={classes.iconCustomCss} onClick={onClick}>
      {isOpen ? <CancelIcon /> : <EditIcon />}
    </div>
  );
};

export const HiddenInfoIcon = ({ onClick, isOpen }: { onClick: any; isOpen: boolean }) => {
  const classes = useRequirementTableStyles();
  return (
    <div className={classes.iconCustomCss} onClick={onClick}>
      {isOpen ? <ExpandLess /> : <InfoIcon />}
    </div>
  );
};

export const useRequirementTableStyles = makeStyles((theme: Theme) => ({
  additionalColumnContentContainer: {
    padding: '25px',
    paddingLeft: '27px',
  },
  table: {
    width: 'auto',
    tableLayout: 'auto',
  },
  hidden: {
    display: 'none',
  },
  requirementFormContainer: {
    padding: theme.spacing(3),
  },
  statusColumn: {
    width: '280px',
  },
  iconCustomCss: {
    cursor: 'pointer',
  },
}));
