import { IContainer } from '../../../../../../../../common/container/IContainer';
import { ConstructorType, inject, injectValue } from '../../../../../../../../common/container/inject';
import { injectPrimitive } from '../../../../../../../../common/store/base/injectPrimitive';
import {
  ApplicationAcceptStatus,
  IApplicationAuditListModel,
  IApplicationModel,
} from '../../../../../../../../service/application/entity/IApplicationModel';
import { IRequirementModel } from '../../../../../../../../service/requirement/entity/IRequirementModel';
import { injectRootService } from '../../../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../../../service/route/IRouterService';
import { ISpecificationEntityModel } from '../../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { ApplicationDetailDomain } from '../../../store/ApplicationDetailDomain';
import { ApplicationRequirementFilterDomain } from '../../../store/filter/ApplicationRequirementFilterDomain';
import { ApplicationHistoryUI, IApplicationModelWithUser } from './ApplicationHistoryUI';
import {
  IApplicationChanges,
  IRequirementChanges,
  ISpecificationChanges,
} from './IApplicationChanges';
import { ISpecificationCategoryModel } from '../../../../../../../../service/specification/category/ISpecificationCategoryModel';
import { injectEntityList } from '../../../../../../../../common/store/base/injectEntityList';

interface IClearThirdVersionReturn {
  renderList: IApplicationModel[];
  thirdVersionAudits: IApplicationModel[];
}
export class ApplicationHistoryDomain {
  constructor(
    public rootDomain: ApplicationDetailDomain,
    public ui: ApplicationHistoryUI = new (injectValue<ConstructorType<ApplicationHistoryUI>>(ApplicationHistoryUI))(),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    protected rootService = injectRootService(rootDomain.layoutDomain.serviceType.value),
    filterDomain: ApplicationRequirementFilterDomain | null = null,
    private container: IContainer | null = null,
    private applicationChangesCache: Map<string, IApplicationChanges> = new Map(),
    public clearThirdVersionAudits: (renderList: IApplicationModel[]) => IClearThirdVersionReturn = (renderList) => {
      return { renderList: renderList, thirdVersionAudits: [] }
    },
    public thirdVersionAuditList = injectPrimitive<IApplicationModel[]>([]),
    public specificationsCategories = injectEntityList<ISpecificationCategoryModel>([]),
  ) { }

  async boot() {
    //@ts-ignore
    const specifiactionsCategoriesData = (await this.rootService.specification.category.search({ limit: 10000, withDeleted: true })).data || []
    this.specificationsCategories.setList(specifiactionsCategoriesData)

    let fullAuditList = await this.rootService.application.entity.getAuditListById(
      this.rootDomain.ui.application.entity.id || '',
    ) as IApplicationAuditListModel[];

    if (!fullAuditList.length) {
      fullAuditList = [
        await this.rootService.application.entity.getById(this.rootDomain.ui.application.entity.id || ''),
      ];
    }

    this.thirdVersionAuditList.setValue(fullAuditList.filter((audit) => {
      return audit.versionNumber?.split('.')[2] !== '0'; // && audit.versionNumber !== '0.0.0';
    }))
    let renderAuditList = fullAuditList.filter((audit) => {
      return audit.versionNumber?.split('.')[2] === '0'; // && audit.versionNumber !== '0.0.0';
    });

    if (
      this.rootDomain.ui.application.entity.acceptStatus === ApplicationAcceptStatus.accepted
    ) {
      if (
        this.rootDomain.ui.application.entity.versionNumber?.split('.')[2] !== '0' ||
        this.rootDomain.ui.application.entity.versionNumber?.split('.')[2] === renderAuditList[0].versionNumber
      ) {
        const thirdVersionAudit = renderAuditList.shift();
        if (thirdVersionAudit) {
          this.thirdVersionAuditList.setValue([...this.thirdVersionAuditList.value, thirdVersionAudit]);
        }
      }
      const afterThirdVersionClearObject = this.clearThirdVersionAudits(renderAuditList);
      renderAuditList = afterThirdVersionClearObject.renderList;
      this.thirdVersionAuditList.setValue(
        [...this.thirdVersionAuditList.value,
        ...afterThirdVersionClearObject.thirdVersionAudits]
      );

      const lastNumberAudit = fullAuditList[0].versionNumber?.split('.') || [0, 0];
      lastNumberAudit[2] = '';
      const lastNumberEntity = this.rootDomain.ui.application.entity.versionNumber?.split('.') || [0, 0];
      lastNumberEntity[2] = '';

      renderAuditList[0] = {
        ...this.rootDomain.ui.application.entity,
        versionNumber: lastNumberEntity.join('.') || lastNumberAudit.join('.'),
        auditUserId: renderAuditList[0]?.auditUserId || fullAuditList[0].auditUserId,
      };
    } else {
      // TODO remove and fix number
      const version = renderAuditList[0]?.versionNumber?.split('.');
      if (version) {
        if (Number(version[1]) === 0) {
          renderAuditList[0].versionNumber = [Number(version[0]) + 1, version[1]].join('.');
        }

        const afterThirdVersionClearObject = this.clearThirdVersionAudits(renderAuditList);
        renderAuditList = afterThirdVersionClearObject.renderList;
        this.thirdVersionAuditList.setValue(
          [...this.thirdVersionAuditList.value,
          ...afterThirdVersionClearObject.thirdVersionAudits]
        );

        if (renderAuditList.length > 1) {
          const nextElementVersion = renderAuditList[1]?.versionNumber?.split('.');
          if (nextElementVersion && version) {
            if (nextElementVersion[0] === version[0] && nextElementVersion[1] === version[1]) {
              renderAuditList[0].versionNumber = [Number(version[0]) + 1, 0].join('.');
            }
          }
        }
      }
    }
    
    // TODO fix sort order
    renderAuditList[0].auditDate = renderAuditList[0]?.auditDate?.getTime()
      ? new Date(renderAuditList[0]?.auditDate?.getTime() + 10 * 1000)
      : renderAuditList[0]?.auditDate;

    const auditListWithUser: IApplicationModelWithUser[] = [...renderAuditList];
    const version = auditListWithUser[0]?.versionNumber?.split('.');
    if (version) {
      auditListWithUser[0].versionNumber = [Number(version[0]), version[1]].join('.');
    }
    let counter = 0;
    for (let i = 0; i < auditListWithUser.length - 1; i++) {
      const audit = auditListWithUser[i];
      const nextAudit = auditListWithUser[i + 1];
      if (counter !== 0 && audit?.versionNumber && nextAudit.versionNumber) {
        const version = audit?.versionNumber?.split('.');
        const nextVersion = nextAudit?.versionNumber?.split('.');

        const majorVersionCurrent = Number(version[0]);
        const minorVersionCurrent = Number(version[1]);

        const majorVersionNext = Number(nextVersion[0]);
        const minorVersionNext = Number(nextVersion[1]);

        if (minorVersionCurrent === 0) {
          audit.versionNumber = [majorVersionCurrent + 1, minorVersionCurrent].join('.');
        } else if (majorVersionCurrent === majorVersionNext && minorVersionNext === minorVersionCurrent) {
          audit.versionNumber = [majorVersionCurrent + 1, 0].join('.');
        } else {
          audit.versionNumber = [majorVersionCurrent, minorVersionCurrent].join('.');
        }
      }
      counter++;

      if (audit.auditUserId) {
        audit.auditUser = {
          login: audit?.auditUser?.login || '',
          displayName: audit?.auditUser?.displayName || '',
          email: audit?.auditUser?.email || '',
        };
      }
    }
    if (auditListWithUser.length > 1) {
      const lastListedAudit = auditListWithUser[auditListWithUser.length - 1];
      if (lastListedAudit.auditUserId) {
        lastListedAudit.auditUser = {
          login: lastListedAudit?.auditUser?.login || '',
          displayName: lastListedAudit?.auditUser?.displayName || '',
          email: lastListedAudit?.auditUser?.email || '',
        };
      }
      const lastVersion = lastListedAudit?.versionNumber?.split('.');
      if (lastVersion) {
        lastListedAudit.versionNumber = [Number(lastVersion[0]) + 1, lastVersion[1]].join('.');
      }
    }
    this.ui.auditApplications.setList([...auditListWithUser]);
    this.ui.fullAuditApplications.setList([...fullAuditList]);
    await this.selectApplication(auditListWithUser[0]);
  }

  async selectApplication(application: IApplicationModel) {
    const isExists = this.ui.selectedApplicationsIds.value.includes(application.id || '');
    if (isExists) {
      this.ui.selectedApplicationsIds.setValue(
        this.ui.selectedApplicationsIds.value.filter((id) => id !== application.id),
      );
      const newSelectedApplicationsSortedList = this.ui.selectedAuditApplication.list.filter(
        (item) => item.id !== application.id,
      );
      newSelectedApplicationsSortedList.sort((a, b) => {
        return (b.auditDate?.getTime() || 0) - (a.auditDate?.getTime() || 0);
      });
      this.ui.selectedAuditApplication.setList(newSelectedApplicationsSortedList);
    } else {
      this.ui.selectedApplicationsIds.value.push(application.id || '');
      let newSelectedApplicationsSortedList = [...this.ui.selectedAuditApplication.list, application];
      newSelectedApplicationsSortedList = newSelectedApplicationsSortedList.sort((a, b) => {
        return (b.auditDate?.getTime() || 0) - (a.auditDate?.getTime() || 0);
      });
      this.ui.selectedAuditApplication.setList(newSelectedApplicationsSortedList as IApplicationModelWithUser[]);
    }

    await this.calculateChanges();
  }

  async calculateTextFieldsChange({
    applicationBefore,
    application,
    applicationChanges
  }: {
    applicationBefore: IApplicationModelWithUser;
    application: IApplicationModelWithUser;
    applicationChanges: IApplicationChanges
  }) {
    const currentTextFields = await this.rootService.specification.entity.getAuditDataForTextFieldsInApplication(application.id || '')

    if (!applicationBefore) { // если старой систмы нет - возвращаем массив всех тектовых полей - как сохраненных - серых
      return currentTextFields.map(field => {
        applicationChanges.counters.specifications.saved = applicationChanges.counters.specifications.saved + 1

        return {
          specification: field,
          isChanged: false,
          compareWithSpecificationChanges: null,
          isNew: false,
          isRemoved: false
        }
      })
    }

    const beforeTextFields = await this.rootService.specification.entity.getAuditDataForTextFieldsInApplication(applicationBefore.id || '')

    const textFieldsChanges = currentTextFields.map((field) => {
      const currentBeforeField = beforeTextFields.find((beforField) => field?.rootId === beforField?.rootId)
      if (!currentBeforeField) { // если поля не было в прошлой версии - оно новое
        applicationChanges.counters.specifications.newest++
        applicationChanges.counters.specifications.saved++
        return {
          specification: field,
          isChanged: false,
          compareWithSpecificationChanges: null,
          isNew: true,
          isRemoved: false
        }
      }
      if (currentBeforeField && currentBeforeField.name !== field.name) { // если поля было в прошлой версии но изменилось название - оно изменнное
        applicationChanges.counters.specifications.edited++
        applicationChanges.counters.specifications.saved++
        return {
          specification: field,
          isChanged: true,
          compareWithSpecificationChanges: currentBeforeField,
          isNew: false,
          isRemoved: false,
        }
      } else { // если поля было в прошлой версии но не изменилось название - оно сохраненное 
        applicationChanges.counters.specifications.saved++
        return {
          specification: field,
          isChanged: false,
          compareWithSpecificationChanges: null,
          isNew: false,
          isRemoved: false,
        }
      }
    }).filter(i => i !== undefined)

    const removedFields = beforeTextFields // все поля которых нет в текущей версии но есть в старой - удаленные 
      .filter((field) => {
        //@ts-ignore
        const isDeleted = !!field.lastDeleteDate;
        const isMissing = !currentTextFields.find((currentField) => currentField.rootId === field.rootId);
        return isDeleted && isMissing;
      })
      .map((field) => {
        applicationChanges.counters.specifications.removed++;
        return {
          specification: field,
          compareWithSpecificationChanges: null,
          isChanged: false,
          isNew: false,
          isRemoved: true
        };
      });

    return [...textFieldsChanges, ...removedFields]
  }

  async calculateValueSpecificationsChange({
    applicationBefore,
    application,
    applicationChanges
  }: {
    applicationBefore: IApplicationModelWithUser;
    application: IApplicationModelWithUser;
    applicationChanges: IApplicationChanges
  }): Promise<any[]> {
    let newSpecifications: ISpecificationChanges[] = []
    let deletedSpecifications: ISpecificationChanges[] = []
    let savedSpecifications: ISpecificationChanges[] = []
    let changedSpecifications: ISpecificationChanges[] = []

    const currentValues = applicationChanges.allSpecifications // все значения спецификаций которые мы искали с учетом аудита текущей версии
    const currentValuesForCurrentApplication = currentValues.filter(spec => {
      return application?.specificationsIds?.find(specId => spec.id === specId && !spec.applicationId)
    }).filter(item => !!item) // значения только для текущей системы  (без текстовых полей)

    const beforeValues = (await this.rootService.specification.entity.search({
      limit: 1000000,
      audit: {
        auditDateInMS: applicationBefore?.auditDate?.getTime() || 0,
        isReplaceId: true,
      },
    })).data || []// все значения спецификаций с учетом аудита прошлой версии

    const beforeValuesForBeforeApplication = beforeValues.filter(spec => {
      return applicationBefore?.specificationsIds?.find(specId => spec.id === specId && !spec.applicationId)
    }).filter(item => !!item)// значения только для прошлой системы (без текстовых полей)

    if (currentValuesForCurrentApplication.length && !beforeValuesForBeforeApplication.length) {
      // если есть значение в текущей версии - но нет в прошлой  - сохраняем как новые требования
      currentValuesForCurrentApplication.forEach(spec => {
        applicationChanges.counters.specifications.newest += 1
        applicationChanges.counters.specifications.saved += 1
        newSpecifications.push({
          specification: spec,
          compareWithSpecificationChanges: null,
          isChanged: false,
          isNew: true,
          isRemoved: false
        })
      })
    }

    if (!currentValuesForCurrentApplication.length && beforeValuesForBeforeApplication.length) {
      // если нет значений в текущей версии - но есть в прошлой  - сохраняем как удаленные требования
      beforeValuesForBeforeApplication.forEach(spec => {
        applicationChanges.counters.specifications.removed += 1
        deletedSpecifications.push({
          specification: spec,
          compareWithSpecificationChanges: null,
          isChanged: false,
          isNew: false,
          isRemoved: true
        })
      })
    }

    if (currentValuesForCurrentApplication.length && beforeValuesForBeforeApplication.length) {
      // если есть значение и у текущей и у прошлой версии - начинаем собирать требования по их типу 
      // новые удаленные сохраненные - без измененнных

      currentValuesForCurrentApplication.forEach(currentSpec => {
        const findedSpec = beforeValuesForBeforeApplication.find(beforeSpec => beforeSpec.rootId === currentSpec.rootId)
        if (findedSpec) {
          applicationChanges.counters.specifications.saved += 1
          savedSpecifications.push({
            specification: currentSpec,
            compareWithSpecificationChanges: null,
            isChanged: false,
            isNew: false,
            isRemoved: false
          })
        } if (!findedSpec) {
          applicationChanges.counters.specifications.newest += 1
          applicationChanges.counters.specifications.saved += 1
          newSpecifications.push({
            specification: currentSpec,
            compareWithSpecificationChanges: null,
            isChanged: false,
            isNew: true,
            isRemoved: false
          })
        }
      })

      beforeValuesForBeforeApplication.forEach(currentSpec => {
        const findedSpec = currentValuesForCurrentApplication.find(beforeSpec => beforeSpec.rootId === currentSpec.rootId)
        if (!findedSpec) {
          applicationChanges.counters.specifications.removed += 1
          deletedSpecifications.push({
            specification: currentSpec,
            compareWithSpecificationChanges: null,
            isChanged: false,
            isNew: false,
            isRemoved: true
          })
        }
      })
    }
    // собраем общий масив требований
    return [
      ...newSpecifications,
      ...deletedSpecifications,
      ...savedSpecifications,
      ...changedSpecifications
    ]
  }

  async calculateSpecificationsChanges({ applicationChanges, application, applicationBefore, compareApplicationChanges }: { // 1
    applicationChanges: IApplicationChanges,
    application: IApplicationModel,
    applicationBefore?: IApplicationModel
    compareApplicationChanges: IApplicationChanges | null
  }) {
    let specificationsChanges: ISpecificationChanges[] = [] // массив изменений который будет отображаться

    const allSpecifications = (await this.rootService.specification.entity.search({
      limit: 1000000,
      audit: {
        auditDateInMS: (application?.auditDate?.getTime() || 0),
        isReplaceId: true,
      }
    })).data || []  // Получаем все характеристики по основной системе с учетом ее аудита
    applicationChanges.allSpecifications = allSpecifications; //и записываем их во все характеристики в объекте изменений системы (хз зачем)

    if (!applicationBefore) {
      // если нам не приходит applicationBefore - тоесть выбрана либо самая старая из выбраных систем либо единственно выбранная
      // мы просто берем все выбранные значения характеристик - форматируем в нужный формат и записываем в applicationChanges
      // отдельно 
      const specificationForCurrentApplication = application.specificationsIds?.map(specId => {
        return allSpecifications.find(spec => spec.id === specId && !spec.applicationId)
      }).filter(item => !!item) || [] //отдельно берем изменения характеристик значений

      const currentTextFields = await this.rootService.specification.entity.getAuditDataForTextFieldsInApplication(application.id || '') //отдельно берем изменения текстовых полей
      specificationsChanges = [...specificationForCurrentApplication, ...currentTextFields].map((spec) => {
        return {
          specification: spec,
          compareWithSpecificationChanges: null,
          isNew: false,
          isRemoved: false,
          isChanged: false,
        }
      }) // мапим все значения как сохраненные - серые 
      applicationChanges.counters.specifications.saved = specificationsChanges.length || 0
    } else {
      // если нам приходят 2 системы - до и после 
      //то мы начинаем искать различия

      const textFieldsChanges = await this.calculateTextFieldsChange({ // сначала в текстовых полях 
        application,
        applicationBefore,
        applicationChanges
      }) || []

      const specificationValueChanges = await this.calculateValueSpecificationsChange({ //затем в простых спецификациях
        application,
        applicationBefore,
        applicationChanges
      }) || []

      //затем собираем в один массив и сортируем
      const resultChangesArray = [...textFieldsChanges, ...specificationValueChanges]
        .sort((a, b) => {
          return Number(b.isChanged) - Number(a.isChanged);
        })
        .sort((a, b) => {
          return Number(b.isNew) - Number(a.isNew);
        })
        .sort((a, b) => {
          return Number(b.isRemoved) - Number(a.isRemoved);
        });

      specificationsChanges.push(...resultChangesArray)

      //в конце для всех изменений ищем его же в прошлой версии которая сохранена в compareApplicationChanges - это нужно для идельного отображения различий текстовых полей
      specificationsChanges = specificationsChanges.map((spec) => {
        const findedSpec = compareApplicationChanges?.specificationsChanges?.find((compareSpec) => compareSpec.specification.rootId === spec.specification.rootId)
        if (findedSpec) {
          return {
            ...spec,
            compareWithSpecificationChanges: findedSpec
          }
        }
        return spec
      })
    }

    applicationChanges.specificationsChanges = specificationsChanges // записываем массив изменений в объект изменений системы 
  }

  async calculateChanges() {
    const applicationsChanges: IApplicationChanges[] = [];
    const selectedReversedApplciations = this.ui.selectedAuditApplication.list.slice().reverse();
    for (let strIndex in selectedReversedApplciations) {
      const index = Number(strIndex);
      let application = selectedReversedApplciations[index];
      const applicationChanges: IApplicationChanges = {
        applicationData: null,
        requirementsChanges: [],
        specificationsChanges: [],
        application: null,
        isSupport: {
          compareApplicationChanges: false,
          compareRequirement: false,
          compareSpecification: false,
        },
        compareWithApplicationChanges: null,
        allRequirements: [],
        allSpecifications: [],
        counters: {
          requirements: {
            newest: 0,
            removed: 0,
            saved: 0,
          },
          specifications: {
            newest: 0,
            removed: 0,
            saved: 0,
            edited: 0,
          },
        },
      };
      const isOldestVersion = index === 0;
      const compareApplicationChanges = isOldestVersion ? null : applicationsChanges[index - 1];
      const cachedApplicationChanges = this.applicationChangesCache.get(
        `${application?.id}-${compareApplicationChanges?.application?.id}`,
      );
      if (cachedApplicationChanges) {
        return cachedApplicationChanges;
      }

      await this.calculateSpecificationsChanges({ applicationChanges, application, applicationBefore: selectedReversedApplciations[index - 1], compareApplicationChanges })
      //todo на будущее вынести логику req в отдельную функцию для лучшей читаемости и для избежания переплетений логики характеристик и требований

      const auditSettings = {
        auditDateInMS: (application?.auditDate?.getTime() || 0) + 10 * 1000,
        isReplaceId: true,
      };
      const applicationData = await this.rootService.application.data
        .getById(application?.dataId || '', auditSettings)
        .catch(() => null);
      let applicationRequirement = await this.rootService.applicationRequirement.entity.getAppReqByAppId(application?.dataId || '', true)
      applicationChanges.applicationData = applicationData;
      applicationChanges.application = application;
      applicationChanges.isSupport.compareApplicationChanges = !!compareApplicationChanges;
      applicationChanges.compareWithApplicationChanges = compareApplicationChanges;
      applicationChanges.isSupport.compareRequirement = !!applicationData;
      applicationChanges.isSupport.compareSpecification = !!application;
      // TODO разобраться с аудитами
      const [allRequirements] = await Promise.all([
        this.rootService.requirement.entity.search({
          limit: 1000000,
          audit: auditSettings,
        }),
      ]);

      applicationChanges.allRequirements = allRequirements?.data || [];
      if (applicationData?.savedRequirementsIds) {
        const requirementIds = applicationData?.savedRequirementsIds?.map((appReqId) => {
          let req = applicationRequirement.find((item) => item.id === appReqId)
          return req?.requirementId;
        })
        applicationData.savedRequirementsIds = [...requirementIds] as string[];
      }

      let applicationRequirements: IRequirementModel[] = [];

      if (applicationChanges.isSupport.compareRequirement) {
        applicationRequirements = applicationChanges.allRequirements.filter((requirement) => {
          return (applicationData?.savedRequirementsIds || []).includes(requirement.id || '');
        }
        );
        applicationChanges.counters.requirements.saved = applicationRequirements.length;
      }

      const compareRequirements = (target: IRequirementModel, next: IRequirementModel) => {
        let isChanged = false;
        if (target.shortName !== next.shortName) {
          isChanged = true;
        }
        if (target.description !== next.description) {
          isChanged = true;
        }
        return isChanged;
      };

      applicationChanges.requirementsChanges = applicationRequirements.map((requirement) => {
        if (applicationChanges.isSupport.compareApplicationChanges) {
          const compareRequirementChanges =
            (compareApplicationChanges?.requirementsChanges || []).find(
              (item) => item.requirement.id === requirement.id,
            ) || null;
          const isNew = !compareRequirementChanges;
          const isChanged =
            !isNew &&
            compareRequirementChanges &&
            compareRequirements(requirement, compareRequirementChanges.requirement);
          if (isNew) {
            applicationChanges.counters.requirements.newest++;
          }
          const changes: IRequirementChanges = {
            requirement,
            compareWithRequirementChanges: compareRequirementChanges,
            isChanged: !!isChanged,
            isNew,
            isRemoved: false,
          };
          return changes;
        } else {
          const changes: IRequirementChanges = {
            requirement,
            compareWithRequirementChanges: null,
            isNew: false,
            isRemoved: false,
            isChanged: false,
          };
          return changes;
        }
      });

      if (applicationChanges.isSupport.compareApplicationChanges) {
        const removedRequirementsChanges =
          applicationChanges.compareWithApplicationChanges?.requirementsChanges
            .filter((item) => !item.isRemoved)
            .reduce((removedRequirements, compareRequirement) => {
              const isExistsInCurrentApplication = applicationChanges.requirementsChanges.find(
                (item) => item.requirement.id === compareRequirement.requirement.id,
              );
              if (!isExistsInCurrentApplication) {
                removedRequirements.push({
                  requirement: compareRequirement.requirement,
                  compareWithRequirementChanges: null,
                  isRemoved: true,
                  isChanged: false,
                  isNew: false,
                });
                applicationChanges.counters.requirements.removed++;
              }
              return removedRequirements;
            }, [] as IRequirementChanges[]) || [];

        applicationChanges.requirementsChanges.push(...removedRequirementsChanges);
      }

      applicationChanges.requirementsChanges = applicationChanges.requirementsChanges
        .sort((a, b) => {
          return Number(b.isChanged) - Number(a.isChanged);
        })
        .sort((a, b) => {
          return Number(b.isNew) - Number(a.isNew);
        })
        .sort((a, b) => {
          return Number(b.isRemoved) - Number(a.isRemoved);
        });

      applicationsChanges.push(applicationChanges);
    }
    applicationsChanges.forEach((applicationChanges) => {
      this.applicationChangesCache.set(
        `${applicationChanges.application?.id}_${applicationChanges.compareWithApplicationChanges?.application?.id}`,
        applicationChanges,
      );
    });
    this.ui.auditApplicationChanges.setList(applicationsChanges.reverse());
  }

  // private async loadApplicationChangesData(application: IApplicationModel, isAudit: boolean = true) {
  //     if (application) {
  //         const auditSettings = isAudit ? {
  //             auditDateInMS: ((application?.auditDate?.getTime() || 0) + (10 * 1000)),
  //             isReplaceId: true
  //         } : {} as any;
  //         const applicationData = await this.rootService.application.data.getById(application.dataId || '', auditSettings);
  //         console.log(applicationData);
  //         const [requirements, specifications] = await Promise.all([
  //             this.rootService.requirement.entity.search({
  //                 limit: 100000,
  //                 filter: {ids: {in: applicationData.savedRequirementsIds}},
  //                 audit: auditSettings
  //             }),
  //             this.rootService.specification.entity.search({
  //                 limit: 100000,
  //                 filter: {ids: {in: application.specificationsIds}},
  //                 audit: auditSettings
  //             }),
  //         ]);
  //
  //         return {
  //             data: applicationData,
  //             requirements: requirements.data,
  //             specifications: specifications.data
  //         }
  //     } else {
  //         return {
  //             data: null,
  //             requirements: [],
  //             specifications: []
  //         }
  //     }
  // }
}
